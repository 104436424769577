<script>
import layoutProfile from "@/views/layouts/layoutProfile";

export default {
	name: "privacy",
	components: {layoutProfile},
}
</script>

<template>
	<layoutProfile>
		<div class="w-100"
			 style="overflow-x: hidden;overflow-y: auto;max-height: calc(100vh - 108px);margin-bottom: 40px;">
			<h1 class="px-4 pt-4 mb-0">Privacy Policy</h1>
			<div class="px-4 pt-4 mb-0">
				<p>Welcome to Romeo (the "Service") provided by M4trix ("we," "us," or "our"). Protecting
					your
					privacy is important to us. This Privacy Policy explains how we collect, use, and disclose
					information
					about you when you use our Service.</p>
				<h5>1. Information We Collect</h5>
				<p>1.1 Information You Provide: When you register for an account, we may collect personal information
					such
					as your name, email address, phone number, and other contact details.</p>
				<p>1.2 Automatically Collected Information: When you use the Service, we may automatically collect
					certain
					information about your device, including your IP address, device type, operating system, and browser
					type.</p>
				<p>1.3 Cookies and Similar Technologies: We may use cookies and similar tracking technologies to collect
					information about your interactions with the Service. Cookies are small files that are stored on
					your
					device to track your preferences and activities.</p>
				<h5>2. How We Use Your Information</h5>
				<p>2.1 Provide and Maintain the Service: We use the information we collect to provide and maintain the
					Service, including processing transactions, authenticating users, and improving the user
					experience.</p>
				<p>2.2 Communicate with You: We may use your contact information to communicate with you about the
					Service,
					including sending promotional emails, newsletters, and updates.</p>
				<p>2.3 Personalization: We may use the information we collect to personalize your experience with the
					Service, such as displaying personalized content and recommendations.</p>
				<h5>3. Information Sharing and Disclosure</h5>
				<p>We may share your information in the following circumstances:</p>

				<p>With service providers: We may share your information with third-party service providers who perform
					services on our behalf, such as payment processing, data analysis, and marketing.</p>

				<p>With affiliates: We may share your information with our affiliates for the purposes described in this
					Privacy Policy.</p>

				<p>For legal purposes: We may disclose your information to comply with applicable laws, regulations, or
					legal processes.</p>

				<h5>4.Your Choices</h5>

				<p>4.1 Account Information: You may update or correct your account information at any time by logging
					into
					your account settings.</p>
				<p>4.2 Email Preferences: You can opt out of receiving promotional emails from us by following the
					instructions provided in the emails.</p>
				<h5>5. Data Security</h5>
				<p>We take reasonable measures to protect your information from unauthorized access, disclosure,
					alteration, or destruction. However, no method of transmission over the internet or electronic
					storage
					is completely secure.</p>
				<h5>6. Changes to This Privacy Policy</h5>
				<p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the
					new Privacy Policy on this page.</p>
			</div>
		</div>
	</layoutProfile>
</template>

<style scoped lang="scss">

</style>